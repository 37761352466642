<template>
  <div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('missionControl', {
      isPlatformAdmin: 'isPlatformAdmin'
    })
  },

  created() {
  }
}
</script>

<style>

</style>